import Img1 from "../img/products/1.jpeg";
import Img2 from "../img/products/2.jpeg";
import Img3 from "../img/products/3.jpeg";
import Img4 from "../img/products/4.jpeg";
// import Img5 from "../img/products/5.jpeg";
import Img6 from "../img/products/6.jpeg";
import Img7 from "../img/products/7.jpeg";
import Img8 from "../img/products/8.jpeg";
import Img9 from "../img/products/9.jpeg";
import Img10 from "../img/products/10.jpeg";
import Img11 from "../img/products/11.jpeg";
import Img12 from "../img/products/12.jpeg";
import Img13 from "../img/products/13.jpeg";
import Img14 from "../img/products/14.jpeg";
import Img15 from "../img/products/15.jpeg";
import PasawayUniversity1 from "../img/products/otherProducts/PasawayUniversityCrewneck1.jpeg";
import PasawayUniversity2 from "../img/products/otherProducts/PasawayUniversityCrewneck2.jpeg";
import FirstKulit1 from "../img/products/otherProducts/FirstKulitHoodie1.jpeg";
import FirstKulit2 from "../img/products/otherProducts/FirstKulitHoodie2.jpeg";
import Kamao1 from "../img/products/otherProducts/Kamao1.jpeg";
import Kamao2 from "../img/products/otherProducts/Kamao2.jpeg";
import KoreanShirt1 from "../img/products/otherProducts/KulitKoreanShirt1.jpeg";
import KoreanShirt2 from "../img/products/otherProducts/KulitKoreanShirt2.jpeg";
import KoreanHoodie1 from "../img/products/otherProducts/KulitKoreanHoodie1.jpeg";
import KoreanHoodie2 from "../img/products/otherProducts/KulitKoreanHoodie2.jpeg";
// import Barangay1 from "../img/products/otherProducts/BarangayKulitCrewneck1.jpeg";
// import Barangay2 from "../img/products/otherProducts/BarangayKulitCrewneck2.jpeg";
import KulitTee1 from "../img/products/otherProducts/KulitTee1.jpeg";
import KulitTee2 from "../img/products/otherProducts/KulitTee2.jpeg";
import PasawaySticker1 from "../img/products/otherProducts/PasawaySticker1.jpeg";
import PasawaySticker2 from "../img/products/otherProducts/PasawaySticker2.jpeg";
import KulitCar1 from "../img/products/otherProducts/KulitCar1.jpeg";
import KulitCar2 from "../img/products/otherProducts/KulitCar2.jpeg";
import BayaniPoster1 from "../img/products/otherProducts/BayaniPoster1.jpeg";
import BayaniPoster2 from "../img/products/otherProducts/BayaniPoster2.jpeg";
import PasawayUniversityGray from "../img/products/otherProducts/PasawayUniversityGray.jpeg";
import PasawayUniversityBlack from "../img/products/otherProducts/PasawayUniversityBlack.jpeg";
import PasawayUniversityWhite from "../img/products/otherProducts/PasawayUniversityWhite.jpeg";
import KamaoChambray from "../img/products/otherProducts/KamaoChambray.jpeg";
import KamaoBlack from "../img/products/otherProducts/KamaoBlack.jpeg";
import KoreanIvory from "../img/products/otherProducts/KoreanIvory.jpeg";
import KoreanGranite from "../img/products/otherProducts/KoreanGranite.jpeg";
import KulitTeeIvory from "../img/products/otherProducts/KulitTeeIvory.jpeg";
import KulitTeeBlack from "../img/products/otherProducts/KulitTeeBlack.jpeg";
import PasawayUniversityGray1 from "../img/products/otherProducts/PasawayUniversityGray1.jpeg";
import PasawayUniversityGray2 from "../img/products/otherProducts/PasawayUniversityGray2.jpeg";
import PasawayUniversityBlack1 from "../img/products/otherProducts/PasawayUniversityBlack1.jpeg";
import PasawayUniversityBlack2 from "../img/products/otherProducts/PasawayUniversityBlack2.jpeg";
import PasawayUniversityWhite1 from "../img/products/otherProducts/PasawayUniversityWhite1.jpeg";
import PasawayUniversityWhite2 from "../img/products/otherProducts/PasawayUniversityWhite2.jpeg";
import KamaoChambray1 from "../img/products/otherProducts/KamaoChambray1.jpeg";
import KamaoChambray2 from "../img/products/otherProducts/KamaoChambray2.jpeg";
import KamaoBlack1 from "../img/products/otherProducts/KamaoBlack1.jpeg";
import KamaoBlack2 from "../img/products/otherProducts/KamaoBlack2.jpeg";
import KoreanGranite1 from "../img/products/otherProducts/KoreanTeeGranite1.jpeg";
import KoreanGranite2 from "../img/products/otherProducts/KoreanTeeGranite2.jpeg";
import KoreanIvory1 from "../img/products/otherProducts/KoreanTeeIvory1.jpeg";
import KoreanIvory2 from "../img/products/otherProducts/KoreanTeeIvory2.jpeg";
import KulitTeeIvory1 from "../img/products/otherProducts/KulitIvory1.jpeg";
import KulitTeeIvory2 from "../img/products/otherProducts/KulitIvory2.jpeg";
import KulitTeeBlack1 from "../img/products/otherProducts/KulitBlack1.jpeg";
import KulitTeeBlack2 from "../img/products/otherProducts/KulitBlack2.jpeg";
import PinoyAkoHoodie1 from "../img/products/otherProducts/PinoyAkoHoodie1.jpeg";
import PinoyAkoHoodie2 from "../img/products/otherProducts/PinoyAkoHoodie2.jpeg";
import PinayAkoHoodie1 from "../img/products/otherProducts/PinayAkoHoodie1.jpeg";
import PinayAkoHoodie2 from "../img/products/otherProducts/PinayAkoHoodie2.jpeg";
import PinoyAkoJacketNavy from "../img/products/otherProducts/PinoyAkoJacketNavy.jpeg";
import PinoyAkoJacketBlack1 from "../img/products/otherProducts/PinoyAkoJacketBlack1.jpeg";
import PinoyAkoJacketBlack2 from "../img/products/otherProducts/PinoyAkoJacketBlack2.jpeg";
import PinoyAkoJacketGray1 from "../img/products/otherProducts/PinoyAkoJacketGray1.jpeg";
import PinoyAkoJacketGray2 from "../img/products/otherProducts/PinoyAkoJacketGray2.jpeg";
import PinayAkoJacketBlack from "../img/products/otherProducts/PinayAkoJacketBlack.jpeg";
import PinayAkoJacketNavy1 from "../img/products/otherProducts/PinayAkoJacketNavy1.jpeg";
import PinayAkoJacketNavy2 from "../img/products/otherProducts/PinayAkoJacketNavy2.jpeg";
import PinayAkoJacketGray1 from "../img/products/otherProducts/PinayAkoJacketGray1.jpeg";
import PinayAkoJacketGray2 from "../img/products/otherProducts/PinayAkoJacketGray2.jpeg";
import PhilippinesSocks from "../img/products/otherProducts/PhilippinesSocks.jpeg";



export const items = [
  {
    id: 1,
    category: "tops",
    img: Img1, // Default image
    description: "Pasaway University Crewneck",
    price: 19.99,
    shippingCost: 8.49,
    additionalShippingCost: 2.09,
    otherImgs: [PasawayUniversity1, PasawayUniversity2],
    specs: "This CrewNeck sweatshirt is designed to reference a certain characteristic that Pinoy’s have. Made with cotton and polyester to ensure your comfort, Perfect for cool weather.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Ivory", img: Img1, additionalImgs: [PasawayUniversity1, PasawayUniversity2] },
      { color: "Gray", img: PasawayUniversityGray, additionalImgs: [PasawayUniversityGray, PasawayUniversityGray1, PasawayUniversityGray2] },
      { color: "Black", img: PasawayUniversityBlack, additionalImgs: [PasawayUniversityBlack, PasawayUniversityBlack1, PasawayUniversityBlack2] },
      { color: "White", img: PasawayUniversityWhite, additionalImgs: [PasawayUniversityWhite, PasawayUniversityWhite1, PasawayUniversityWhite2] },
    ],
  },  
  {
    id: 2,
    category: "tops",
    img: Img2,
    description: "Classic Kulit Hoodie",
    price: 29.99,
    shippingCost: 8.49,
    additionalShippingCost: 2.09,
    otherImgs: [FirstKulit1, FirstKulit2],
    specs: "A classic styled hoodie representing the Kulit brand. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", img: Img2, additionalImgs: [FirstKulit1, FirstKulit2] },
    ],
  },
  {
    id: 3,
    category: "tops",
    img: Img3,
    description: "Kamao T-Shirt",
    price: 19.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [Kamao1, Kamao2],
    specs:
      "This Tee is designed with a 2d pixelated style and is inspired by our pambansang kamao to represent the bravery and dedication of Filipinos. Made with 100% ringspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton", 
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Ivory", img: Img3, additionalImgs: [Kamao2, Kamao1] },
      { color: "Chambray", img: KamaoChambray, additionalImgs: [KamaoChambray, KamaoChambray1, KamaoChambray2] },
      { color: "Black", img: KamaoBlack, additionalImgs: [KamaoBlack, KamaoBlack1, KamaoBlack2] },
    ],
  },
  {
    id: 10,
    category: "tops",
    img: Img4,
    description: "Korean Kulit T-Shirt",
    price: 19.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [KoreanShirt1, KoreanShirt2],
    specs:
      "A simple styled tee representing the Kulit brand in Korean. Made with 100% wingspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", img: Img4, additionalImgs: [KoreanShirt1, KoreanShirt2]},
      { color: "Granite", img: KoreanGranite, additionalImgs: [KoreanGranite, KoreanGranite1, KoreanGranite2] },
      { color: "Ivory", img: KoreanIvory, additionalImgs: [KoreanIvory, KoreanIvory1, KoreanIvory2] },
    ],
  },
  {
    id: 5,
    category: "tops",
    img: Img6,
    description: "Kulit T-Shirt",
    price: 19.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [KulitTee1, KulitTee2],
    specs:
      "A classic styled tee representing the Kulit brand. Made with 100% wingspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", img: Img6, additionalImgs: [KulitTee1, KulitTee2]},
      { color: "Ivory", img: KulitTeeIvory, additionalImgs: [KulitTeeIvory, KulitTeeIvory1, KulitTeeIvory2] },
      { color: "Black", img: KulitTeeBlack, additionalImgs: [KulitTeeBlack, KulitTeeBlack1, KulitTeeBlack2] },
    ],
  },
  {
    id: 11,
    category: "other",
    img: Img7,
    description: "Pasaway Sticker",
    price: 1.99,
    shippingCost: 4.69, // Base shipping cost
    additionalShippingCost: 0.09, // Reduced cost for each additional item
    otherImgs: [PasawaySticker1, PasawaySticker2],
    specs:
      "A decal representing the Pasaway University. Made with a high quality white vinyl, these decals deliver great looks that are water, scratch, and UV-resistant.",
    texture: "White vinyl with a satin finish",
    size: "10cm x 5cm",
  },
  {
    id: 13,
    category: "other",
    img: Img8,
    description: "Kulit Drift Sticker",
    price: 1.99,
    shippingCost: 4.69, // Base shipping cost
    additionalShippingCost: 0.09, // Reduced cost for each additional item
    otherImgs: [KulitCar1, KulitCar2],
    specs:
      "A decal representing a Japanese drift vehicle and is design with a cool blue background. Made with a high quality white vinyl, these decals deliver great looks that are water, scratch, and UV-resistant.",
    texture: "White vinyl with a satin finish",
    size: "10cm x 5cm",
  },
  {
    id: 14,
    category: "other",
    img: Img9,
    description: "Bayani Poster",
    price: 7.99,
    shippingCost: 6.79, // Base shipping cost
    additionalShippingCost: 0.99, // Reduced cost for each additional item
    prices: { 
      "9 x 11": 7.99, 
      "12 x 18": 14.99, 
      "24 x 36": 24.99 
    },
    otherImgs: [BayaniPoster1, BayaniPoster2],
    specs: "A poster representing a bayani in the Philippines, José Rizal. Made with a modern style poster and a collage of different colored paint splatters in the background. Made with museum grade paper, available in 3 sizes.",
    texture: "Cotton",
    sizes: ["9 x 11", "12 x 18", "24 x 36"],
  },  
  {
    id: 9,
    category: "tops",
    img: Img10,
    description: "Korean Kulit Hoodie",
    price: 29.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [KoreanHoodie1, KoreanHoodie2],
    specs:
      "A simple styled hoodie representing the Kulit brand in Korean. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Charcoal", img: Img10, additionalImgs: [KoreanHoodie1, KoreanHoodie2] },
    ],
  },
  {
    id: 4,
    category: "tops",
    img: Img11,
    description: "Pinoy Ako Hoodie",
    price: 29.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinoyAkoHoodie1, PinoyAkoHoodie2],
    specs:
      "A Hoodie representing the Philippines, designed with the colors and other elements on the Philippine flag. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", img: Img11, additionalImgs: [PinoyAkoHoodie1, PinoyAkoHoodie2] },
    ],
  },
  {
    id: 6,
    category: "tops",
    img: Img12,
    description: "Pinay Ako Hoodie",
    price: 29.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinayAkoHoodie1, PinayAkoHoodie2],
    specs:
      "A Hoodie representing the Philippines, designed with the colors and other elements on the Philippine flag. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day. ",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", img: Img12, additionalImgs: [PinayAkoHoodie1, PinayAkoHoodie2] },
    ],
  },
  {
    id: 12,
    category: "accessories",
    img: Img13,
    description: "Philippines Socks",
    price: 9.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PhilippinesSocks],
    specs:
      "A simple styled hoodie representing the Kulit brand in Korean. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
  },
  {
    id: 7,
    category: "tops",
    img: Img14,
    description: "Pinoy Ako Jacket",
    price: 39.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinoyAkoJacketNavy],
    specs:
      "A full comfy zip-up hoodie, featuring air-jet spun yarn for a softer fleece with reduced pilling. Designed with colors and other elements on the Philippine flag to represent the Philippines. Made with 50% cotton and 50% polyester, once put on, it will be impossible to take off.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Navy", img: Img14, additionalImgs: [PinoyAkoJacketNavy] },
      { color: "Gray", img: PinoyAkoJacketGray1, additionalImgs: [PinoyAkoJacketGray1, PinoyAkoJacketGray2] },
      { color: "Black", img: PinoyAkoJacketBlack1, additionalImgs: [PinoyAkoJacketBlack1, PinoyAkoJacketBlack2] },
    ],
  },
  {
    id: 8,
    category: "tops",
    img: Img15,
    description: "Pinay Ako Jacket",
    price: 39.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinayAkoHoodie1, PinayAkoHoodie2],
    specs:
      "A full comfy zip-up hoodie, featuring air-jet spun yarn for a softer fleece with reduced pilling. Designed with colors and other elements on the Philippine flag to represent the Philippines. Made with 50% cotton and 50% polyester, once put on, it will be impossible to take off.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", img: Img15, additionalImgs: [PinayAkoJacketBlack] },
      { color: "Gray", img: PinayAkoJacketGray1, additionalImgs: [PinayAkoJacketGray1, PinayAkoJacketGray2] },
      { color: "Navy", img: PinayAkoJacketNavy1, additionalImgs: [PinayAkoJacketNavy1, PinayAkoJacketNavy2] },
    ],
  },
];
