import CategoriesItemKitchen from "./CategoriesItemKitchen.jsx";
import Footer from "../Footer.jsx";
import Newsletter from "../Newsletter.jsx";

function Kitchen() {
  return (
    <>
      <CategoriesItemKitchen />
      <Newsletter />
      <Footer />
    </>
  );
}

export default Kitchen;
